import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "admin",
    title: "Admin",
    type: "collapse",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "users",
        title: "Users",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={16} />,
        navLink: "/users",
      },
      {
        id: "promocode",
        title: "Promocodes",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={16} />,
        navLink: "/promocode",
      },
      {
        id: "tarifs",
        title: "Tarifs",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={16} />,
        navLink: "/tarifs",
      },
      {
        id: "blackIps",
        title: "Black Ips",
        type: "item",
        icon: <Icon.Settings size={16} />,
        permissions: ["fuckYouPidor"],
        navLink: "/black-ips",
      },
      {
        id: "isp",
        title: "Isp",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={16} />,
        navLink: "/isp",
      },
      {
        id: "userAgent",
        title: "User Agents",
        permissions: ["fuckYouPidor"],
        type: "item",
        icon: <Icon.Settings size={16} />,
        navLink: "/user-agent",
      },
    ]
  },
  {
    id: "compaigns",
    title: "Campaigns",
    type: "item",
    icon: <Icon.List size={16} />,
    navLink: "/campaigns",
  },
  {
    id: "statistics",
    title: "Statistics",
    type: "item",
    icon: <Icon.TrendingUp size={16} />,
    navLink: "/statistics",
  },
  {
    id: "knowledgeBase",
    title: "Knowledge Base",
    type: "item",
    icon: <Icon.CheckSquare size={16} />,
    navLink: "/knowledge-base",
  },
  {
    id: "payments",
    title: "Payments",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/payments",
  },
  {
    id: "affiliate",
    title: "Affiliate",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/affiliate",
  },
  {
    id: "support",
    title: "Support",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/support",
  }
]

export default horizontalMenuConfig
