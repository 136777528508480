import * as Icon from "react-feather"

import languages, { getSystemLanguage } from "../views/pages/languages"

import React from "react"

const horizontalMenuConfig = () => [
  {
    id: "admin",
    title: "Admin",
    permissions: ["fuckYouPidor"],
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "users",
        title: "Users",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/users",
      },
      {
        id: "promocode",
        permissions: ["fuckYouPidor"],
        title: "Promocodes",
        type: "item",
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/promocode",
      },
      {
        id: "tarifs",
        title: "Tarifs",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/tarifs",
      },
      {
        id: "blackIps",
        title: "Black Ips",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/black-ips",
      },
      {
        id: "isp",
        title: "Isp",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/isp",
      },
      {
        id: "userAgent",
        title: "User Agents",
        permissions: ["fuckYouPidor"],
        type: "item",
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/user-agent",
      },
      {
        id: "coins",
        title: "Coins",
        permissions: ["fuckYouPidor"],
        type: "item",
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/coins",
      },
      {
        id: "paymentsAdmin",
        title: "Payments",
        permissions: ["fuckYouPidor"],
        type: "item",
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/payments",
      },
      {
        id: "promos",
        title: "Promo",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/promos",
      },
      {
        id: "editPosts",
        title: "Edit Posts",
        type: "item",
        permissions: ["fuckYouPidor"],
        icon: <Icon.Settings size={20} />,
        navLink: "/admin/edit-posts",
      },
    ]
  },

  {
    id: "compaigns",
    title: languages[getSystemLanguage()].menu.compaigns,
    type: "item",
    icon: <Icon.List size={20} />,
    navLink: "/campaigns",
  },
  {
    id: "blackList",
    title: languages[getSystemLanguage()].menu.blackList,
    type: "item",
    icon: <Icon.Edit size={20} />,
    navLink: "/black-list",
  },
  {
    id: "knowledgeBase",
    title: languages[getSystemLanguage()].menu.knowledgeBase,
    icon: <Icon.Info size={20} />,
    type: "external-link",
    newTab: true,
    navLink: "https://cloakit.wiki",
  },
  {
    id: "affiliate",
    title: languages[getSystemLanguage()].menu.affiliate,
    type: "item",
    icon: <Icon.GitPullRequest size={20} />,
    navLink: "/affiliate",
  },
  {
    id: "payments",
    title: languages[getSystemLanguage()].menu.payments,
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    navLink: "/payments",
  },
  {
    id: "support",
    title: languages[getSystemLanguage()].menu.support,
    type: "external-link",
    newTab: true,
    icon: <Icon.LifeBuoy size={20} />,
    navLink: "https://t.me/cloakit",
  }
]

export default horizontalMenuConfig
